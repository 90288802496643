<template>
  <div class="card" :class="{recommended: isRecommend}">
    <div class="part content">
      <span />
      <p class="level font-semibold">
        {{ cardData.levelDegreeName }}
      </p>
      <div>
        <span>Difficulty</span>
        <Rate :rate-level="cardData.levelDegreeOrder" :max="cardData.levelDegreeOrder" on-color="#FFCF1B" />
      </div>
    </div>
    <div v-if="cardData.levelDegreeDesc" class="part bottom">
      {{ cardData.levelDegreeDesc }}
    </div>
  </div>
</template>

<script>
import Rate from 'components/common/Rate'

export default {
  components: { Rate },
  props: {
    isRecommend: {
      default: false,
      type: Boolean,
    },
    cardData: {
      default: () => ({}),
      type: Object,
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  width: 100%;

  .part {
    background: #f8f9fa;
    border-radius: 10px;
    margin-bottom: 1px;
    position: relative;

    &.content {
      padding: px2vw(32);

      .level {
        font-size: px2vw(48);
        color: var(--txt-color-lv1);
        line-height: px2vw(58);
        margin-bottom: px2vw(20);
      }

      div {
        display: flex;

        span {
          margin-right: px2vw(20);
          padding: px2vw(4) px2vw(8);
          font-size: px2vw(24);
          font-weight: 500;
          line-height: px2vw(28);
          color: var(--txt-color-lv1);
          background: rgba(222, 226, 231, 0.5);
          border-radius: px2vw(8);
        }
      }
    }

    &.bottom {
      padding: px2vw(20) px2vw(32);
      font-size: px2vw(26);
      font-weight: 500;
      color: var(--txt-color-lv2);
      line-height: px2vw(30);
    }
  }

  &.recommended {
    .part {
      background: var(--txt-color-link3);

      &.content {
        >span {
          position: absolute;
          top: px2vw(-16);
          right: px2vw(-28);
          padding: 2px 8px 9px 14px;
          width: px2vw(254);
          height: px2vw(64);
          text-align: center;
          font-weight: 600;
          background-image: url("~assets/images/test/recommend.png");
          background-size: cover;
          color: #fff;
          z-index: 2;
        }
        .level { color: var(--txt-color-link); }

        div {
          span {
            background: rgba(255, 133, 10, 0.1);
            color: #793d00;
          }
        }
      }

      &.bottom {
        color: var(--txt-color-link);
        background: var(--txt-color-link3);
      }
    }
  }
}
</style>
